import httpProvider from 'Providers/http.js';

export default {
    getOrganizationByGuid(guid) {
        return new Promise((resolve, reject) => {
            httpProvider.get('/organization/guid/' + guid).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
}