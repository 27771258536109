<template>
  <g clip-path="url(#clip0_1544_21951)">
    <path
      d="M16.6665 1.66675H3.33317C2.4127 1.66675 1.6665 2.41294 1.6665 3.33341V6.66675C1.6665 7.58722 2.4127 8.33341 3.33317 8.33341H16.6665C17.587 8.33341 18.3332 7.58722 18.3332 6.66675V3.33341C18.3332 2.41294 17.587 1.66675 16.6665 1.66675Z"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.6665 11.6667H3.33317C2.4127 11.6667 1.6665 12.4129 1.6665 13.3334V16.6667C1.6665 17.5872 2.4127 18.3334 3.33317 18.3334H16.6665C17.587 18.3334 18.3332 17.5872 18.3332 16.6667V13.3334C18.3332 12.4129 17.587 11.6667 16.6665 11.6667Z"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5 5H5.00729"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5 15H5.00729"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
  <defs>
    <clipPath id="clip0_1544_21951">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</template>
<script>
export default {
  name: "IconServer",
};
</script>
