<template>
  <g clip-path="url(#clip0_1544_18170)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.49967 9.99735C2.49967 5.85522 5.85754 2.49735 9.99967 2.49735C14.1418 2.49735 17.4997 5.85522 17.4997 9.99735C17.4997 14.1395 14.1418 17.4974 9.99967 17.4974C5.85754 17.4974 2.49967 14.1395 2.49967 9.99735ZM9.99967 0.830688C4.93706 0.830688 0.833008 4.93474 0.833008 9.99735C0.833008 15.06 4.93706 19.164 9.99967 19.164C15.0623 19.164 19.1663 15.06 19.1663 9.99735C19.1663 4.93474 15.0623 0.830688 9.99967 0.830688ZM6.66634 9.16663C6.2061 9.16663 5.83301 9.53972 5.83301 9.99996C5.83301 10.4602 6.2061 10.8333 6.66634 10.8333H13.333C13.7932 10.8333 14.1663 10.4602 14.1663 9.99996C14.1663 9.53972 13.7932 9.16663 13.333 9.16663H6.66634Z"
      fill="currentColor"
    />
  </g>
  <defs>
    <clipPath id="clip0_1544_18170">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</template>
<script>
export default {
  name: "IconMinusOutlined",
};
</script>
