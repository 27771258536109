<template>
  <path
    d="M4.16667 8.33337C3.24619 8.33337 2.5 9.07957 2.5 10C2.5 10.9205 3.24619 11.6667 4.16667 11.6667C5.08714 11.6667 5.83333 10.9205 5.83333 10C5.83333 9.07957 5.08714 8.33337 4.16667 8.33337Z"
    fill="currentColor"
  />
  <path
    d="M8.33333 10C8.33333 9.07957 9.07953 8.33337 10 8.33337C10.9205 8.33337 11.6667 9.07957 11.6667 10C11.6667 10.9205 10.9205 11.6667 10 11.6667C9.07953 11.6667 8.33333 10.9205 8.33333 10Z"
    fill="currentColor"
  />
  <path
    d="M14.1667 10C14.1667 9.07957 14.9129 8.33337 15.8333 8.33337C16.7538 8.33337 17.5 9.07957 17.5 10C17.5 10.9205 16.7538 11.6667 15.8333 11.6667C14.9129 11.6667 14.1667 10.9205 14.1667 10Z"
    fill="currentColor"
  />
</template>
<script>
export default {
  name: "IconDotsHorisontal",
};
</script>
