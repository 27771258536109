<template>
  <path
    d="M14.2929 7.36669L8.16621 13.4934C7.41565 14.2439 6.39767 14.6656 5.33621 14.6656C4.27475 14.6656 3.25677 14.2439 2.50621 13.4934C1.75565 12.7428 1.33398 11.7248 1.33398 10.6634C1.33398 9.6019 1.75565 8.58392 2.50621 7.83336L8.21954 2.12002C8.71992 1.61876 9.39892 1.33681 10.1072 1.33618C10.8154 1.33556 11.495 1.61631 11.9962 2.11669C12.4975 2.61706 12.7794 3.29607 12.78 4.00433C12.7807 4.71259 12.4999 5.3921 11.9995 5.89336L6.27288 11.6067C6.02269 11.8569 5.68336 11.9974 5.32954 11.9974C4.97572 11.9974 4.6364 11.8569 4.38621 11.6067C4.13602 11.3565 3.99547 11.0172 3.99547 10.6634C3.99547 10.3095 4.13602 9.97021 4.38621 9.72002L10.0462 4.06669"
    stroke="currentColor"
    stroke-width="1.33333"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</template>
<script>
export default {
  name: "IconAttachment",
};
</script>
