<template>
  <path
    d="M8 8L16 16"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M16 8L8 16"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</template>
<script>
export default {
  name: "IconCross",
};
</script>
