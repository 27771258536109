<template>
  <div class="filters">
    <div
      v-for="filter in filters"
      :key="filter.id"
      class="filters__filter"
      :style="`
            flex: ${filter.width || 1};
            min-width:${(filter.width || 1) * 90}px
        `"
    >
      <component
        :is="filter.type"
        v-model="values[filter.id]"
        v-bind="filter.attr"
      />
    </div>
  </div>
</template>

<script>
import Input from "../components/Form/Input.vue";
import Select from "../components/Form/Select.vue";

export default {
  name: "Filters",
  components: { Input, Select },
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      values: {},
    };
  },
  watch: {
    values: {
      handler(value) {
        this.$emit("update:modelValue", value);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>

.filters {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: $space-lg;
  width: 100%;
  padding: $space-xs;
  background-color: $filters-background-color;
  border-radius: $border-radius-md;

  @media (max-width: $breakpoint-mobile) {
    &__filter {
      min-width: 100% !important;
    }
  }
}
</style>
