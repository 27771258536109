<template>
  <div :class="`notification notification_${type}`">
    <icon-base
      :icon-name="icons[type]"
      width="21"
      height="21"
      class="notification__icon"
    />
    <div class="notification__content">
      <slot />
    </div>
  </div>
</template>

<script>
import IconBase from "../components/Icons/IconBase.vue";

export default {
  name: "Notification",
  components: { IconBase },
  data() {
    return {
      icons: {
        information: "information-outlined",
        success: "check-outlined",
        warning: "exclamation-outlined",
        error: "cross-outlined",
      },
    };
  },
  props: {
    type: {
      type: String,
      default: "warning",
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  $root: &;

  display: flex;
  align-items: start;
  gap: $space-xs;
  padding: $space-md;
  border-radius: $border-radius-md;

  &__content {
    font-size: $font-size-md;
    line-height: $line-height-md;
  }

  &_information {
    background-color: $notification-information-color;

    #{$root}__icon {
      color: $notification-information-icon-color;
    }
  }
  &_success {
    background-color: $notification-success-color;

    #{$root}__icon {
      color: $notification-success-icon-color;
    }
  }
  &_warning {
    background-color: $notification-warning-color;

    #{$root}__icon {
      color: $notification-warning-icon-color;
    }
  }
  &_error {
    background-color: $notification-error-color;

    #{$root}__icon {
      color: $notification-error-icon-color;
    }
  }

  &__content {
    flex: 1;
  }
}
</style>
