export default {
  components: {
    input: "input",
    select: "select",
    checkbox: "checkbox",
  },
  actions: {
    text: "text",
    button: "button",
    checkbox: "checkbox",
  },
  serverModes: {
    add: "add",
    edit: "edit",
  },
};
