<template>
  <g clip-path="url(#clip0_1424_14360)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.8048 2.1381C12.945 1.99791 13.1352 1.91915 13.3334 1.91915C13.5317 1.91915 13.7218 1.99791 13.862 2.1381C14.0022 2.27829 14.081 2.46843 14.081 2.6667C14.081 2.86496 14.0022 3.0551 13.862 3.19529L7.65925 9.39805L6.24966 9.75045L6.60206 8.34086L12.8048 2.1381ZM13.3334 0.585815C12.7815 0.585815 12.2523 0.80505 11.862 1.19529L5.52868 7.52862C5.44324 7.61406 5.38263 7.72112 5.35332 7.83834L4.68665 10.505C4.62986 10.7322 4.69642 10.9725 4.86201 11.1381C5.0276 11.3037 5.26792 11.3703 5.4951 11.3135L8.16177 10.6468C8.27899 10.6175 8.38605 10.5569 8.47149 10.4714L14.8048 4.1381C15.1951 3.74786 15.4143 3.21858 15.4143 2.6667C15.4143 2.11481 15.1951 1.58553 14.8048 1.19529C14.4146 0.80505 13.8853 0.585815 13.3334 0.585815ZM2.66675 2.00006C2.13632 2.00006 1.62761 2.21077 1.25253 2.58585C0.877462 2.96092 0.666748 3.46963 0.666748 4.00006V13.3334C0.666748 13.8638 0.877462 14.3725 1.25253 14.7476C1.62761 15.1227 2.13631 15.3334 2.66675 15.3334H12.0001C12.5305 15.3334 13.0392 15.1227 13.4143 14.7476C13.7894 14.3725 14.0001 13.8638 14.0001 13.3334V8.66673C14.0001 8.29854 13.7016 8.00006 13.3334 8.00006C12.9652 8.00006 12.6667 8.29854 12.6667 8.66673V13.3334C12.6667 13.5102 12.5965 13.6798 12.4715 13.8048C12.3465 13.9298 12.1769 14.0001 12.0001 14.0001H2.66675C2.48994 14.0001 2.32037 13.9298 2.19534 13.8048C2.07032 13.6798 2.00008 13.5102 2.00008 13.3334V4.00006C2.00008 3.82325 2.07032 3.65368 2.19534 3.52866C2.32037 3.40363 2.48994 3.33339 2.66675 3.33339H7.33341C7.7016 3.33339 8.00008 3.03492 8.00008 2.66673C8.00008 2.29854 7.7016 2.00006 7.33341 2.00006H2.66675Z"
      fill="currentColor"
    />
  </g>
  <defs>
    <clipPath id="clip0_1424_14360">
      <rect width="16" height="16" fill="white" />
    </clipPath>
  </defs>
</template>
<script>
export default {
  name: "IconEdit",
};
</script>
