<template>
  <div class="wrppr">
    <div
      :class="[
        'switcher',
        {
          switcher_on: on,
          switcher_click: clickAvailable,
        },
      ]"
      @click="clickAvailable && $emit('switcherClick')"
    >
      <div class="switcher__content">
        <div class="switcher__point"></div>
      </div>
    </div>
    <div class="label"><slot /></div>
  </div>
</template>

<script>
export default {
  name: "Switcher",
  props: {
    on: {
      type: Boolean,
      default: false,
    },
    clickAvailable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.wrppr {
  display: flex;
  align-items: center;
  gap: 4px;
}
.switcher {
  $root: &;

  padding: 1px;
  background-color: $switcher-background-color;
  border-radius: $border-radius-lg;

  &__content {
    background: $color-white;
    padding: 2px;
    border-radius: $border-radius-lg - 1px;
  }

  &__point {
    margin-right: $switcher-point-size;
    width: $switcher-point-size;
    height: $switcher-point-size;
    background: $switcher-background-color;
    border-radius: $border-radius-lg - 2px;
  }

  &_on {
    #{$root}__content {
      background: $gradient-2;
    }
    #{$root}__point {
      margin-right: auto;
      margin-left: $switcher-point-size;
      background: $gradient;
    }
  }

  &_click {
    cursor: pointer;
  }
}

.label {
  font-size: $font-size-md;
}
</style>
