<template>
  <section class="ex">
    <Chips :chips="chips" />

    <Notification>Предупреждение</Notification>
    <Notification type="error">Ошибка</Notification>

    <Switcher>Выключен</Switcher>
    <Switcher on>Включен</Switcher>

    <FormSelect v-bind="select" />

    <Checkbox v-model="selected" :label="selected ? 'Выбран' : 'Не выбран'" />
  </section>
</template>

<script>
import Chips from "../components/Chips.vue";
import Notification from "../components/Notification.vue";
import Switcher from "../components/Switcher.vue";
import FormSelect from "../components/Form/Select.vue";
import Checkbox from "../components/Form/Checkbox.vue";

export default {
  components: {
    Chips,
    Notification,
    Switcher,
    FormSelect,
    Checkbox,
  },
  data() {
    return {
      chips: [
        {
          id: "srvrwrks",
          type: "complited",
          icon: "check",
          label: "Сервер работает",
        },
        {
          id: "anthng",
          type: "complited",
          label: "Без иконки",
        },
      ],
      select: {
        placeholder: "Все адреса",
        mode: "single",
        options: [
          {
            id: 1,
            label: "Малышева 71А",
          },
          {
            id: 2,
            label: "Чапаева 23Б",
          },
          {
            id: 3,
            label: "Сакко и Ванцетти 105",
          },
        ],
      },
      selected: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.ex {
  margin: 0px $space-lg;
  width: 100%;

  * {
    margin: $space-lg 0px;
  }
}
</style>
