<template>
  <path
    d="M13.3337 4L6.00033 11.3333L2.66699 8"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</template>
<script>
export default {
  name: "IconCheck",
};
</script>
