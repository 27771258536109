<template>
  <section class="tabs">
    <div
      v-for="tab in tabs"
      :key="tab.id"
      :class="['tabs__tab', { tabs__tab_active: tab.id === modelValue }]"
      @click="$emit('update:modelValue', tab.id)"
    >
      {{ tab.label }}
    </div>
  </section>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: String,
      Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>

.tabs {
  display: flex;
  gap: $space-xl;

  &__tab {
    position: relative;
    padding-bottom: 8px;
    color: $tabs-tab-color;
    font-weight: $font-weight-lg;
    cursor: pointer;

    &_active {
      color: unset;
      pointer-events: none;

      &::after {
        content: "";
        position: absolute;
        right: 0px;
        bottom: 0px;
        left: 0px;
        height: 3px;
        background-color: $color-default;
      }
    }
  }
}
</style>
