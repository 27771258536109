<template>
  <rect
    x="0.5"
    y="0.5"
    width="23"
    height="23"
    rx="3.5"
    fill="url(#paint0_linear_1544_24445)"
    stroke="#D0D3E5"
  />
  <path
    d="M6 11C6 10.4477 6.44772 10 7 10H17C17.5523 10 18 10.4477 18 11V13C18 13.5523 17.5523 14 17 14H7C6.44772 14 6 13.5523 6 13V11Z"
    fill="url(#paint1_linear_1544_24445)"
  />
  <defs>
    <linearGradient
      id="paint0_linear_1544_24445"
      x1="0"
      y1="12"
      x2="24"
      y2="12"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#E2FFEB" />
      <stop offset="1" stop-color="#E7F4FF" />
    </linearGradient>
    <linearGradient
      id="paint1_linear_1544_24445"
      x1="6"
      y1="12"
      x2="18"
      y2="12"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#4CD174" />
      <stop offset="1" stop-color="#6DB8F2" />
    </linearGradient>
  </defs>
</template>
<script>
export default {
  name: "IconCheckboxPartly",
};
</script>
