<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.99968 2.66663C5.99968 2.55011 6.06465 2.3778 6.22108 2.22136C6.37752 2.06493 6.54983 1.99996 6.66634 1.99996H9.33301C9.44952 1.99996 9.62184 2.06493 9.77827 2.22136C9.93471 2.3778 9.99968 2.55011 9.99968 2.66663V3.33331H5.99968V2.66663ZM4.66634 3.33331V2.66663C4.66634 2.11647 4.93471 1.62212 5.27827 1.27855C5.62184 0.934991 6.11619 0.666626 6.66634 0.666626H9.33301C9.88317 0.666626 10.3775 0.934991 10.7211 1.27855C11.0646 1.62212 11.333 2.11647 11.333 2.66663V3.33331H12.6663H13.9997C14.3679 3.33331 14.6663 3.63179 14.6663 3.99998C14.6663 4.36817 14.3679 4.66665 13.9997 4.66665H13.333V13.3333C13.333 13.8835 13.0646 14.3778 12.7211 14.7214C12.3775 15.0649 11.8832 15.3333 11.333 15.3333H4.66634C4.11619 15.3333 3.62184 15.0649 3.27827 14.7214C2.93471 14.3778 2.66634 13.8835 2.66634 13.3333V4.66665H1.99967C1.63148 4.66665 1.33301 4.36817 1.33301 3.99998C1.33301 3.63179 1.63148 3.33331 1.99967 3.33331H3.33301H4.66634ZM3.99968 13.3333V4.66665H11.9997V13.3333C11.9997 13.4498 11.9347 13.6221 11.7783 13.7786C11.6218 13.935 11.4495 14 11.333 14H4.66634C4.54983 14 4.37752 13.935 4.22108 13.7786C4.06465 13.6221 3.99968 13.4498 3.99968 13.3333ZM6.66634 6.66663C7.03453 6.66663 7.33301 6.9651 7.33301 7.33329V11.3333C7.33301 11.7015 7.03453 12 6.66634 12C6.29815 12 5.99967 11.7015 5.99967 11.3333V7.33329C5.99967 6.9651 6.29815 6.66663 6.66634 6.66663ZM9.99967 7.33329C9.99967 6.9651 9.70119 6.66663 9.333 6.66663C8.96481 6.66663 8.66634 6.9651 8.66634 7.33329V11.3333C8.66634 11.7015 8.96481 12 9.333 12C9.70119 12 9.99967 11.7015 9.99967 11.3333V7.33329Z"
    fill="currentColor"
  />
</template>
<script>
export default {
  name: "IconTrash",
};
</script>
