<template>
  <rect
    opacity="0.3"
    x="0.5"
    y="0.5"
    width="23"
    height="23"
    rx="3.5"
    fill="url(#paint0_linear_1955_1719)"
    stroke="url(#paint1_linear_1955_1719)"
  />
  <path
    d="M17.1454 6.77734L10.0122 13.3949L6.99556 10.1756L5 12.0455L9.8736 17.2465L19 8.78764L17.1454 6.77734Z"
    fill="url(#paint2_linear_1955_1719)"
  />
  <defs>
    <linearGradient
      id="paint0_linear_1955_1719"
      x1="0"
      y1="12"
      x2="18.7347"
      y2="21.932"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#4CD174" />
      <stop offset="1" stop-color="#6DB8F2" />
    </linearGradient>
    <linearGradient
      id="paint1_linear_1955_1719"
      x1="0"
      y1="12"
      x2="18.7347"
      y2="21.932"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#2ABB56" />
      <stop offset="1" stop-color="#3996DD" />
    </linearGradient>
    <linearGradient
      id="paint2_linear_1955_1719"
      x1="5"
      y1="12.0119"
      x2="14.3172"
      y2="18.6172"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#3DCB68" />
      <stop offset="1" stop-color="#58A6E2" />
    </linearGradient>
  </defs>
</template>
<script>
export default {
  name: "IconCheckboxSelected",
};
</script>
