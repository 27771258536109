export default {
    state: {
        contacts: [],
        contactsMeta: {}
    },
    getters: {
        contacts: state => state.contacts,
        contactsMeta: state => state.contactsMeta,
    },
    mutations: {
        SET_CONTACTS(state, payload) {
            state.contacts = payload;
        },
        ADD_NEW_CONTACT(state, payload) {
            state.contacts.push(payload);
        },
        REMOVE_CONTACTS(state, payload) {
            state.contacts = state.contacts.filter(server => !payload.includes(server.suid));
        },
        SET_CONTACTS_META(state, payload) {
            state.contactsMeta = payload;
        }
    },
    actions: {
        setContacts({commit}, payload) {
            commit('SET_CONTACTS', payload);
        },
        addNewContact({commit}, payload) {
            commit('ADD_NEW_CONTACT', payload);
        },
        removeContact({commit}, suid) {
            commit('REMOVE_CONTACTS', suid);
        },
        setContactsMeta({commit}, payload) {
            commit('SET_CONTACTS_META', payload);
        }
    }
}