<template>
  <section
    :class="[
      `header header_${type}`,
      {
        header_spaced: spaced,
      },
    ]"
  >
    <div class="header__title">
      <slot name="title" />
    </div>
    <slot name="addons" />
  </section>
</template>

<script>
export default {
  name: "Header",
  props: {
    type: {
      type: String,
      default: "h1",
    },
    spaced: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  $root: &;

  width: 100%;

  display: flex;
  align-items: center;
  gap: $space-md;

  &_h1 {
    #{$root}__title {
      @include h1;
    }
  }
  &_h2 {
    #{$root}__title {
      @include h2;
    }
  }
  &_h3 {
    #{$root}__title {
      @include h3;
    }
  }
  &_h4 {
    #{$root}__title {
      @include h4;
    }
  }

  &_spaced {
    #{$root}__title {
      flex: 1;
    }
  }
}
</style>
