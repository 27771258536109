<template>
    {{ message }}
</template>

<script>

import AuthService from '../services/AuthService.js';
import ErrorMessages from '../providers/ErrorMessages.js';

export default {
    name: 'MobileAuth',
    data() {
        return {
            message: null,
            authService: AuthService,
            errorMessages: ErrorMessages
        }
    },
    mounted() {
        this.authService.getOauthLink().then(response => {
            if (response.data && response.data.authentication_url) {
                localStorage.setItem('isMobileAuth', true);
                window.location.href = response.data.authentication_url;
            } else {
                this.message = this.errorMessages.authError;
            }
        }).catch(error => {
            this.message = this.errorMessages.authError;
            console.log(error)
        });
    },
    methods: {

    }
}

</script>


<style scoped lang="scss">



</style>