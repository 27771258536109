<template>
  <section class="icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      :width="width"
      :height="height"
      :viewBox="viewBox"
      :aria-labelledby="iconName"
    >
      <component :is="`icon-${iconName}`" />
    </svg>
    <span v-if="label">{{ label }}</span>
  </section>
</template>

<script>
import IconCheck from "./Icons/14/IconCheck.vue";

import IconEdit from "./Icons/16/IconEdit.vue";
import IconAttachment from "./Icons/16/IconAttachment.vue";
import IconTrash from "./Icons/16/IconTrash.vue";
import IconUploadFile from "./Icons/16/IconUploadFile.vue";

import IconPlusOutlined from "./Icons/20/IconPlusOutlined.vue";
import IconMinusOutlined from "./Icons/20/IconMinusOutlined.vue";
import IconServer from "./Icons/20/IconServer.vue";

import IconExclamationOutlined from "./Icons/24/IconExclamationOutlined.vue";
import IconCrossOutlined from "./Icons/24/IconCrossOutlined.vue";
import IconCross from "./Icons/24/IconCross.vue";
import IconCheckbox from "./Icons/24/IconCheckbox.vue";
import IconCheckboxSelected from "./Icons/24/IconCheckboxSelected.vue";
import IconCheckboxPartly from "./Icons/24/IconCheckboxPartly.vue";
import IconCheckOutlined from "./Icons/24/IconCheckOutlined.vue";
import IconDotsHorisontal from "./Icons/24/IconDotsHorisontal.vue";
import IconChevronLeft from "./Icons/24/IconChevronLeft.vue";

export default {
  name: "IconBase",
  components: {
    IconCheck,
    IconCross,
    IconEdit,
    IconAttachment,
    IconTrash,
    IconUploadFile,
    IconPlusOutlined,
    IconMinusOutlined,
    IconServer,
    IconExclamationOutlined,
    IconCrossOutlined,
    IconCheckbox,
    IconCheckboxSelected,
    IconCheckboxPartly,
    IconCheckOutlined,
    IconDotsHorisontal,
    IconChevronLeft,
  },
  props: {
    iconName: {
      type: String,
      default: "plus-outlined",
    },
    width: {
      type: [Number, String],
      default: 20,
    },
    height: {
      type: [Number, String],
      default: 20,
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      defaultViewBoxValue: "0 0 16 16",
      viewBoxValues: {
        "0 0 14 14": ["check"],
        "0 0 20 20": ["plus-outlined", "minus-outlined", "server"],
        "0 0 24 24": [
          "exclamation-outlined",
          "cross-outlined",
          "cross",
          "checkbox",
          "checkbox-selected",
          "checkbox-partly",
          "check-outlined",
          "dots-horisontal",
          "chevron-left",
        ],
      },
    };
  },
  computed: {
    viewBox() {
      const viewBoxValue = Object.entries(this.viewBoxValues).find(([k, v]) =>
        v.includes(this.iconName)
      )?.[0];
      return viewBoxValue || this.defaultViewBoxValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  display: flex;
  align-items: center;
  gap: $space-xxxxs;
}
</style>
