export default {
  data() {
    return {
      currentPage: 1,
      pagination: {
        totalItems: 10,
        itemsByPage: 10,
      },
    };
  },
  methods: {
    initPagination(currentPage, totalItems, itemsByPage) {
      this.currentPage = currentPage ?? 1;
      this.pagination.totalItems = totalItems ?? 1;
      this.pagination.itemsByPage = itemsByPage ?? 1;
    },
    getPaginationSettings() {
      return {
        currentPage: this.currentPage,
        totalItems: this.pagination.totalItems,
        itemsByPage: this.pagination.itemsByPage
      }
    }
  }
};
