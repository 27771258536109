<template>
  <section class="back" @click="$router.push({ name: routerPageName })">
    <icon-base icon-name="chevron-left" class="back__icon" />
    <slot />
  </section>
</template>

<script>
import IconBase from "../components/Icons/IconBase.vue";

export default {
  name: "BackButton",
  components: { IconBase },
  props: {
    routerPageName: {
      type: String,
      default: "Auth",
    },
  },
};
</script>

<style lang="scss" scoped>
.back {
  display: flex;
  gap: $space-xxxxs;
  align-items: center;
  color: #707695;
  cursor: pointer;
}
</style>
