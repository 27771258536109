<template>
  <path
    d="M16.0644 8.31485C16.0644 8.21172 15.9801 8.12735 15.8769 8.12735L14.3301 8.13438L12.0004 10.9117L9.67305 8.13673L8.12384 8.12969C8.02072 8.12969 7.93634 8.21173 7.93634 8.31719C7.93634 8.36173 7.95275 8.40391 7.98087 8.43907L11.0301 12.0719L7.98087 15.7024C7.95255 15.7367 7.93684 15.7797 7.93634 15.8242C7.93634 15.9274 8.02072 16.0117 8.12384 16.0117L9.67305 16.0047L12.0004 13.2274L14.3277 16.0024L15.8746 16.0094C15.9777 16.0094 16.0621 15.9274 16.0621 15.8219C16.0621 15.7774 16.0457 15.7352 16.0176 15.7L12.973 12.0695L16.0222 8.43673C16.0504 8.40391 16.0644 8.35938 16.0644 8.31485Z"
    fill="currentColor"
  />
  <path
    d="M12 1.52344C6.20156 1.52344 1.5 6.225 1.5 12.0234C1.5 17.8219 6.20156 22.5234 12 22.5234C17.7984 22.5234 22.5 17.8219 22.5 12.0234C22.5 6.225 17.7984 1.52344 12 1.52344ZM12 20.7422C7.18594 20.7422 3.28125 16.8375 3.28125 12.0234C3.28125 7.20938 7.18594 3.30469 12 3.30469C16.8141 3.30469 20.7188 7.20938 20.7188 12.0234C20.7188 16.8375 16.8141 20.7422 12 20.7422Z"
    fill="currentColor"
  />
</template>
<script>
export default {
  name: "IconCrossOutlined",
};
</script>
