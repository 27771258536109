<template>
  <section class="element">
    <div
      :class="[
        'element__label',
        {
          element__label_required: required,
        },
      ]"
    >
      {{ label }}
    </div>
    <component :is="type" v-model="elementValue" v-bind="attr"></component>
  </section>
</template>

<script>
import Input from "./Input.vue";
import Select from "./Select.vue";

export default {
  name: "LabeledElement",
  components: {
    Input,
    Select,
  },
  props: {
    modelValue: {
      type: [String, Array],
      default: null,
    },
    type: {
      type: String,
      default: "input",
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    attr: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    elementValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.element {
  &__label {
    margin-bottom: $space-xxs;
    color: $form-list-label-color;
    font-size: $font-size-md;
    line-height: $line-height-md;

    &_required {
      &::after {
        content: "*";
        margin-left: 4px;
        color: $color-red;
      }
    }
  }
}
</style>
