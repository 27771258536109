<template>
  <section class="notifications">
    <div
      v-for="notification in notifications"
      :key="notification.id"
      class="notifications__one"
    >
      <icon-base
        :icon-name="notification.type"
        width="24"
        height="24"
        :class="`notifications__one-icon
        notifications__one-icon_${notification.type}`"
      />
      <div class="notifications__one-text">
        {{ notification.text }}
      </div>
      <icon-base
        icon-name="cross"
        class="notifications__one-remove"
        @click="onClose(notification.id)"
      />
    </div>
  </section>
</template>

<script>
import IconBase from "./Icons/IconBase.vue";

export default {
  name: "Notifications",
  components: { IconBase },
  computed: {
    notifications() {
      return this.$store.getters.notifications;
    },
  },
  methods: {
    onClose(id) {
      this.$store.commit("REMOVE_NOTIFICATION", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  gap: $space-md;
  padding: $space-md;

  &__one {
    display: flex;
    gap: $space-md;
    min-width: 376px;
    z-index: 1000;
    padding: $space-xmd;
    border-radius: $border-radius-md;
    box-shadow: 0px 28px 65px rgba(208, 216, 225, 0.137324),
      0px 6.25417px 14.5186px rgba(208, 216, 225, 0.202676),
      0px 1.86203px 4.32257px rgba(208, 216, 225, 0.34);
    background-color: $color-white;

    &-icon {
      &_check-outlined {
        color: $color-green;
      }

      &_cross-outlined {
        color: $color-red;
      }
    }

    &-text {
      flex: 1;
    }

    &-remove {
      cursor: pointer;
    }
  }
}
</style>
