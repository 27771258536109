export default {
    state: {
        servers: [],
        serversMeta: {},
        serverErrorMsg: null,
        serverRules: []
    },
    getters: {
        servers: state => state.servers,
        serversMeta: state => state.serversMeta,
        serverErrorMsg: state => state.serverErrorMsg,
        serverRules: state => state.serverRules
    },
    mutations: {
        SET_SERVERS(state, payload) {
            state.servers = payload;
        },
        ADD_NEW_SERVER(state, payload) {
            state.servers.push(payload);
        },
        REMOVE_SERVERS(state, payload) {
            state.servers = state.servers.filter(server => !payload.includes(server.suid));
        },
        SET_SERVERS_META(state, payload) {
            state.serversMeta = payload;
        },
        SET_SERVER_ERROR_MESSAGE(state, payload) {
            state.serverErrorMsg = payload;
            setTimeout(() => {
                state.serverErrorMsg = null;
            }, 3000)
        },
        SET_SERVER_RULES(state, payload) {
            state.serverRules[payload.serverId] = payload.rules;
        },
        REMOVE_SERVER_RULES(state, payload) {
            if (payload.serverId && state.serverRules[payload.serverId]) {
                delete state.serverRules[payload.serverId];
            } else {
                state.serverRules = [];
            }
        }
    },
    actions: {
        setServers({commit}, payload) {
            commit('SET_SERVERS', payload);
        },
        addNewServer({commit}, payload) {
            commit('ADD_NEW_SERVER', payload);
        },
        removeServers({commit}, suid) {
            commit('REMOVE_SERVERS', suid);
        },
        setServersMeta({commit}, payload) {
            commit('SET_SERVERS_META', payload);
        },
        setServerErrorMessage({commit}, payload) {
            commit('SET_SERVER_ERROR_MESSAGE', payload);
        },
        setServerRules({commit}, payload) {
            commit('SET_SERVER_RULES', payload);
        },
        removeServerRules({commit}, payload) {
            commit('REMOVE_SERVER_RULES', payload);
        }
    }
}