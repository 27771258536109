<template>
  <g clip-path="url(#clip0_1544_21962)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.58579 1.54147C2.96086 1.1664 3.46957 0.955688 4 0.955688H9.33332H9.66667C9.84348 0.955688 10.013 1.02593 10.1381 1.15095L13.8047 4.81762C13.9298 4.94264 14 5.11221 14 5.28902V13.6224C14 14.1528 13.7893 14.6615 13.4142 15.0366C13.0391 15.4116 12.5304 15.6224 12 15.6224H4C3.46957 15.6224 2.96086 15.4116 2.58579 15.0366C2.21071 14.6615 2 14.1528 2 13.6224V2.95569C2 2.42526 2.21071 1.91655 2.58579 1.54147ZM4 2.28902H8.66666V5.62235C8.66666 5.99054 8.96513 6.28902 9.33332 6.28902H12.6667V13.6224C12.6667 13.7992 12.5964 13.9687 12.4714 14.0938C12.3464 14.2188 12.1768 14.289 12 14.289H4C3.82319 14.289 3.65362 14.2188 3.5286 14.0938C3.40357 13.9687 3.33333 13.7992 3.33333 13.6224V2.95569C3.33333 2.77888 3.40357 2.60931 3.5286 2.48428C3.65362 2.35926 3.82319 2.28902 4 2.28902ZM9.99999 4.95569H12.0572L9.99999 2.89849V4.95569ZM7.52859 7.81762C7.5925 7.7537 7.66617 7.70547 7.7448 7.67294C7.82283 7.64059 7.90833 7.62262 7.99799 7.62236L7.99999 7.62235L8.00199 7.62236C8.17194 7.62286 8.34173 7.68795 8.47139 7.81762L10.4714 9.81762C10.7317 10.078 10.7317 10.5001 10.4714 10.7604C10.211 11.0208 9.78894 11.0208 9.52859 10.7604L8.66666 9.8985V12.289C8.66666 12.6572 8.36818 12.9557 7.99999 12.9557C7.6318 12.9557 7.33332 12.6572 7.33332 12.289V9.8985L6.47139 10.7604C6.21104 11.0208 5.78893 11.0208 5.52859 10.7604C5.26824 10.5001 5.26824 10.078 5.52859 9.81762L7.52859 7.81762Z"
      fill="currentColor"
    />
  </g>
  <defs>
    <clipPath id="clip0_1544_21962">
      <rect
        width="16"
        height="16"
        fill="white"
        transform="translate(0 0.289062)"
      />
    </clipPath>
  </defs>
</template>
<script>
export default {
  name: "IconUploadFile",
};
</script>
