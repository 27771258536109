<template>
  <section class="pagination">
    <icon-base
      icon-name="chevron-left"
      :class="[
        'pagination__chevron',
        {
          pagination__chevron_disabled: isChevronDisabled['left'],
        },
      ]"
      width="24"
      height="24"
      @click="switchPage(modelValue - 1)"
    />
    <template v-for="(page, index) in pagesToShow" :key="page">
      <icon-base
        v-if="page > pagesToShow[index - 1] + 1"
        icon-name="dots-horisontal"
        class="pagination__dots"
      />
      <span
        :class="[
          'pagination__page',
          {
            pagination__page_current: page === modelValue,
          },
        ]"
        @click="switchPage(page)"
      >
        {{ page }}
      </span>
    </template>
    <icon-base
      icon-name="chevron-left"
      :class="[
        'pagination__chevron',
        'pagination__chevron_rotated',
        {
          pagination__chevron_disabled: isChevronDisabled['right'],
        },
      ]"
      width="24"
      height="24"
      @click="switchPage(modelValue + 1)"
    />
  </section>
</template>

<script>
const FIRST_PAGE = 1;
const PAGES_FROM_SIDE = 3;
const PAGES_FROM_CENTER = 1;

import IconBase from "../components/Icons/IconBase.vue";

export default {
  name: "Pagination",
  components: { IconBase },
  props: {
    modelValue: {
      type: Number,
      default: 1,
    },
    totalItems: {
      type: Number,
      default: 10,
    },
    itemsByPage: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    lastPage() {
      const lastPage = Math.ceil(this.totalItems / this.itemsByPage);

      return lastPage > 0 ? lastPage : 1;
    },
    pagesToShow() {
      const pages = [FIRST_PAGE, this.lastPage];

      if (this.modelValue === FIRST_PAGE) {
        for (let i = this.modelValue; i <= PAGES_FROM_SIDE; i++) {
          if (i === this.lastPage) break;
          pages.push(i);
        }
      } else if (this.modelValue === this.lastPage) {
        for (
          let i = this.modelValue;
          i > this.modelValue - PAGES_FROM_SIDE;
          i--
        ) {
          if (i === FIRST_PAGE) break;
          pages.push(i);
        }
      } else {
        for (
          let i = this.modelValue - PAGES_FROM_CENTER;
          i <= this.modelValue + PAGES_FROM_CENTER;
          i++
        ) {
          pages.push(i);
        }
      }

      return [...new Set(pages)].sort((a, b) => a - b);
    },
    isChevronDisabled() {
      return {
        left: this.modelValue === FIRST_PAGE,
        right: this.modelValue === this.lastPage,
      };
    },
  },
  methods: {
    switchPage(page) {
      this.$emit("update:modelValue", page);
      this.$emit("switchPage", page);
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  align-items: center;
  gap: $space-xs;
  margin: $space-lg 0px;
  font-weight: $font-weight-lg;
  user-select: none;

  &__page,
  &__chevron {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
      border-color: $pagination-current-background;
    }

    &_current {
      background-color: $pagination-current-background;
      pointer-events: none;
    }

    &_disabled {
      color: $pagination-disabled-chevron-color;
      pointer-events: none;
    }
  }

  &__dots {
    padding-top: 5px;
    color: $pagination-dots-color;
    pointer-events: none;
  }

  &__chevron {
    padding-top: 5px;

    &_rotated {
      transform: rotate(180deg);
    }
  }
}
</style>
