export default {
    state: {
        properties: [],
        propertiesWithContacts: []
    },
    getters: {
        properties: state => state.properties,
        propertiesWithContacts: state => state.propertiesWithContacts,
    },
    mutations: {
        SET_PROPERTIES(state, payload) {
            state.properties = payload;
        },
        SET_PROPERTIES_WITH_CONTACTS(state, payload) {
            state.propertiesWithContacts = payload;
        },
        DELETE_PROPERTY_WITH_CONTACTS(state, payload) {
            state.propertiesWithContacts = state.propertiesWithContacts.filter(property => property.puid != payload)
        }
    },
    actions: {
        setProperties({commit}, payload) {
            commit('SET_PROPERTIES', payload);
        },
        setPropertiesWithContacts({commit}, payload) {
            commit('SET_PROPERTIES_WITH_CONTACTS', payload);
        },
        deletePropertyWithContacts({commit}, payload) {
            commit('DELETE_PROPERTY_WITH_CONTACTS', payload)
        }
    }
}