export default {
  state: {
    notifications: [],
  },
  getters: {
    notifications: (state) => state.notifications,
  },
  mutations: {
    SET_NOTIFICATION(state, payload) {
      state.notifications.push(payload);
    },
    REMOVE_NOTIFICATION(state, payload) {
      state.notifications = state.notifications.filter(
        (item) => item.id !== payload
      );
    },
  },
  actions: {
    setNotification({ commit }, payload) {
      const id = payload.id || new Date().getTime();
      const type = payload.type || "check-outlined";
      const text = payload.text || "Успешно";
      const timeout = payload.timeout || 3000;

      commit("SET_NOTIFICATION", { id, type, text, timeout });

      setTimeout(() => {
        commit("REMOVE_NOTIFICATION", id);
      }, timeout);
    },
  },
};
