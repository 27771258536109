<template>
  <div ref="wrapper" class="wrapper" рушпре :style="`height: ${wrapperHeight}`">
    <div :class="['actions', { actions_fixed: !isIntersecting }]">
      <div
        v-for="action in actions"
        :key="action.id"
        :class="`actions__action actions__action_${action.type}`"
      >
        <span v-if="action.type === types.actions.text">
          {{ action.label }}
        </span>
        <Button
          v-if="action.type === types.actions.button"
          v-bind="action.attr"
        />
        <Checkbox
          :selected="values[action.id]"
          v-if="action.type === types.actions.checkbox"
          v-bind="action.attr"
        />
      </div>
      <small style="color: red;">{{ $store.getters.serverErrorMsg ?? '' }}</small>
    </div>
  </div>
</template>

<script>
import types from "../config/types.js";
import Button from "../components/Form/Button.vue";
import Checkbox from "../components/Form/Checkbox.vue";

export default {
  name: "Actions",
  components: { Button, Checkbox },
  props: {
    values: {
      type: Object,
      default: {}
    },
    actions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      wrapperHeight: "auto",
      isIntersecting: true,
      types,
    };
  },  
  methods: {
    onIntersectionChange(entry) {
      this.isIntersecting = entry.isIntersecting;
      console.log(this.isIntersecting);
    },
  },
  mounted() {
    const wrapper = this.$refs.wrapper;
    this.wrapperHeight = `${wrapper.offsetHeight + 24}px`;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry) this.isIntersecting = entry.isIntersecting;
      },
      { threshold: 1 }
    );
    observer.observe(wrapper);
  },
};
</script>

<style lang="scss" scoped>

.actions {
  display: flex;
  align-items: center;
  gap: $space-md;
  overflow-y: auto;
  padding: $space-md;
  border-radius: $border-radius-md;
  background-color: $color-white;

  &__action {
    &_text * {
      font-weight: $font-weight-lg;
    }
  }

  &_fixed {
    position: fixed;
    right: $space-xxl;
    bottom: $space-lg;
    left: $space-xxl;
    box-shadow: 0px 4px 14px $actions-box-shadow-color;
  }
}
</style>
