<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13.1629 4.33709C13.529 4.7032 13.529 5.2968 13.1629 5.66291L8.82582 10L13.1629 14.3371C13.529 14.7032 13.529 15.2968 13.1629 15.6629C12.7968 16.029 12.2032 16.029 11.8371 15.6629L6.83709 10.6629C6.47097 10.2968 6.47097 9.7032 6.83709 9.33709L11.8371 4.33709C12.2032 3.97097 12.7968 3.97097 13.1629 4.33709Z"
    fill="currentColor"
  />
</template>
<script>
export default {
  name: "IconChevronLeft",
};
</script>
