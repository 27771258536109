<template>
  <g clip-path="url(#clip0_1544_25090)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.99967 2.50004C5.85754 2.50004 2.49967 5.8579 2.49967 10C2.49967 14.1422 5.85754 17.5 9.99967 17.5C14.1418 17.5 17.4997 14.1422 17.4997 10C17.4997 5.8579 14.1418 2.50004 9.99967 2.50004ZM0.833008 10C0.833008 4.93743 4.93706 0.833374 9.99967 0.833374C15.0623 0.833374 19.1663 4.93743 19.1663 10C19.1663 15.0627 15.0623 19.1667 9.99967 19.1667C4.93706 19.1667 0.833008 15.0627 0.833008 10ZM9.99968 5.83337C10.4599 5.83337 10.833 6.20647 10.833 6.66671V9.16669H13.333C13.7932 9.16669 14.1663 9.53978 14.1663 10C14.1663 10.4603 13.7932 10.8334 13.333 10.8334H10.833V13.3334C10.833 13.7936 10.4599 14.1667 9.99968 14.1667C9.53945 14.1667 9.16635 13.7936 9.16635 13.3334V10.8334H6.66634C6.2061 10.8334 5.83301 10.4603 5.83301 10C5.83301 9.53978 6.2061 9.16669 6.66634 9.16669H9.16635V6.66671C9.16635 6.20647 9.53945 5.83337 9.99968 5.83337Z"
      fill="currentColor"
    />
  </g>
  <defs>
    <clipPath id="clip0_1544_25090">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</template>
<script>
export default {
  name: "IconPlusOutlined",
};
</script>
