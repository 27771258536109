export default {
    state: {
        isLoading: false,
    },
    getters: {
        isLoading: state => state.isLoading,
    },
    mutations: {
        SET_IS_LOADING(state, payload) {
            state.isLoading = payload;
        },
    },
    actions: {
        setIsLoading({commit}, payload) {
            commit('SET_IS_LOADING', payload);
        },
    }
}