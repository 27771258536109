<template>
  <path
    d="M15.6753 8L10.5802 13.0568L8.4254 10.5967L7 12.0257L10.4811 16L17 9.53617L15.6753 8Z"
    fill="currentColor"
  />
  <path
    d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
    fill="currentColor"
  />
</template>
<script>
export default {
  name: "IconCheckOutlined",
};
</script>
