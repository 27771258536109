<template>
  <rect
    x="0.5"
    y="0.5"
    width="23"
    height="23"
    rx="3.5"
    fill="white"
    stroke="#D0D3E5"
  />
</template>
<script>
export default {
  name: "IconCheckbox",
};
</script>
